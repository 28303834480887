import {
  ref, watch, onMounted, getCurrentInstance
} from 'vue'
import { customerRetailUseCase } from '@/domain/usecase'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import TabsNavCustomer from '@/views/master/customers/customer/components/TabsNavCustomer.vue'
import DialogLevelUp from '@/views/shared-components/dialog/DialogLevelUpCustomer.vue'
import moment from 'moment'

export default {
  name: 'CustomerRetail',
  components: {
    TabsNavCustomer,
    DialogLevelUp
  },
  setup() {
    const app = getCurrentInstance()
    const { $confirm, $toast, $strInd } = app!.appContext.config.globalProperties
    const store = useStore()
    const data = ref([])
    const dataForm = ref(null)
    const inputSearch = ref('')
    const op = ref()
    const router = useRouter()
    const searchInput = ref(null)
    const searchData = ref('')
    const isShowDialogLevelUp = ref(false)
    const filter = {
      pageSize: 10,
      pageNumber: 1,
      totalRecords: 0,
      search: '',
      filterField: ['fullname'],
      custom: [['groupUserName', '=', 'retail']]
    }
    const searchParam = ref('fullname')
    const searchParamOptions = [
      { nama: 'Nama', value: 'fullname' },
      { nama: 'Email', value: 'email' },
      { nama: 'No. Telp', value: 'phoneNumber' }
    ]
    const route = useRoute()
    const redirectAdd = () => {
      // dataForm.value = null
      router.push({
        name: 'master-pelanggan-retail-tambah'
      })
    }

    const redirectEdit = (param: any) => {
      router.push({
        name: 'master-pelanggan-retail-edit',
        params: {
          idUser: param.Id
        }
      })
    }

    const showDropDown = (evt: any, idData: any) => {
      dataForm.value = idData
      op.value.toggle(evt)
    }

    // const getAllData = async () => {
    //   const response = await customerRetailUseCase.getAll({
    //     skip: filter.skip,
    //     top: filter.top,
    //     search: searchData.value,
    //     filter: (store.state.customView.filter((val: any) => val.toLowerCase() === 'nofilpelre').length === 0) ? `KotaId eq ${store.state.appActiveUser.agent.KotaId}` : null
    //   })
    //   if (response.error) {
    //     $toast.add({
    //       severity: 'error', detail: $strInd.toast.errorAllData, group: 'bc', life: 1500
    //     })
    //   } else {
    //     data.value = response.result
    //     filter.totalRecords = response.count
    //     // console.log('data Customer Retail', response)
    //   }
    //   store.dispatch('hideLoading')
    // }

    const getAllData = async () => {
      const response = await customerRetailUseCase.getAllV2(filter)
      console.log('res', response)
      if (response.error) {
        $toast.add({
          severity: 'error', detail: $strInd.toast.errorAllData, group: 'bc', life: 1500
        })
      } else {
        // data.value = response.result.Data.map((x: any) => {
        //   return x
        // })
        data.value = response.result.Data.map((x: any) => {
          x.Alamat = x.AdditionalInfo?.PrimaryAddress !== null ? x.AdditionalInfo?.PrimaryAddress.Alamat1 : '-'
          x.registerDate = x.CreatedAt ? moment(x.CreatedAt).format('DD MMMM YYYY HH:mm:ss') : '-'
          return x
        })
        filter.totalRecords = response.result.Count
        // console.log('data Customer Retail', response)
      }
      store.dispatch('hideLoading')
    }

    const onPage = (event: any) => {
      filter.pageNumber = event.page + 1
      getAllData()
    }
    const searchChange = (val: any) => {
      // console.log(val)
      filter.pageNumber = 1
      filter.search = val.toLowerCase()
      filter.filterField = []
      filter.filterField.push(searchParam.value)
      getAllData()
    }
    const isActived = async (val: any) => {
      store.dispatch('showLoading')
      const userid = val.Id
      const dataList = [{
        op: 'replace',
        path: '/isActive',
        value: val.IsActive
      }]
      const response = await customerRetailUseCase.changeIsActiveV2(userid, dataList)
      if (response.error) {
        val.IsActive = !val.IsActive
        $toast.add({
          severity: 'error', detail: $strInd.toast.errorChangeStatus, group: 'bc', life: 1500
        })
      } else {
        $toast.add({
          severity: 'success', detail: 'Berhasil merubah status aktif', group: 'bc', life: 1500
        })
      }
      store.dispatch('hideLoading')
    }

    const changeIsActive = (val: any) => {
      $confirm.require({
        header: 'Status Aktif',
        message: $strInd.confirm.msgChange,
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          isActived(val)
        },
        reject: () => {
          val.IsActive = !val.IsActive
        }
      })
    }

    const classStatuscard = (val: any) => {
      if (val.IsActive) {
        return 'card-no-shadow mb-4'
      }
      return 'card-no-shadow mb-4 background-white-darken-1'
    }

    const deleteData = (Id: any) => {
      $confirm.require({
        header: 'Pelanggan Retail',
        message: $strInd.confirm.msgDelete,
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          store.dispatch('showLoading')
          const response = await customerRetailUseCase.deleteData(Id)
          // console.log('reposne delete', response)
          if (response.error) {
            $toast.add({
              severity: 'error', detail: 'Gagal menghapus pelanggan retail, silahkan coba kembali', group: 'bc', life: 3000
            })
            store.dispatch('hideLoading')
          } else {
            $toast.add({
              severity: 'success', detail: response.result.detail ?? response.result.Detail, group: 'bc', life: 3000
            })
            getAllData()
          }
        }
      })
    }

    const hideLevelUpDialog = () => {
      isShowDialogLevelUp.value = false
    }

    const showLevelUpDialog = () => {
      // console.log('val', val)
      isShowDialogLevelUp.value = true
    }

    const processSubmitLevelup = async (id: any) => {
      store.dispatch('showLoading')
      const userid = id
      const dataList = [{
        op: 'replace',
        path: '/userLevelId',
        value: 1
      }]
      const response = await customerRetailUseCase.changeIsActiveV2(userid, dataList)
      if (response.error) {
        $toast.add({
          severity: 'error', detail: $strInd.toast.errorChangeStatus, group: 'bc', life: 1500
        })
      } else {
        $toast.add({
          severity: 'success', detail: 'Berhasil merubah status aktif', group: 'bc', life: 1500
        })
      }
      getAllData()
      isShowDialogLevelUp.value = false
      store.dispatch('hideLoading')
    }

    onMounted(() => {
      store.dispatch('showLoading')
      getAllData()
    })

    return {
      dataForm,
      op,
      data,
      inputSearch,
      redirectAdd,
      redirectEdit,
      changeIsActive,
      getAllData,
      classStatuscard,
      showDropDown,
      deleteData,
      router,
      route,
      searchChange,
      searchInput,
      searchData,
      filter,
      onPage,
      isShowDialogLevelUp,
      hideLevelUpDialog,
      showLevelUpDialog,
      processSubmitLevelup,
      searchParam,
      searchParamOptions
    }
  }
}
